import { Button, Icon, Link, Tag } from 'components/ui'
import { getValidIcon } from 'components/ui/Icon'
import { LinkCardStoryblok, PaymentSectionStoryblok } from 'lib/storyblok/types'
import { getAnchorFromCmsLink, textByLine } from 'lib/utils/content'
import { cn } from 'lib/utils/tailwind'

import { StoryblokImage } from '../StoryblokImage'

type Props = {
  block: PaymentSectionStoryblok
}

export const PaymentSection = ({ block }: Props): JSX.Element => {
  const { tag, title, description, link, cards } = block

  return (
    <div
      className={cn(
        'max-w-screen-desktop mx-auto flex flex-col gap-8 px-5 py-10 md:px-20 lg:py-15',
        cards.length > 3
          ? 'xl:flex-row xl:justify-between xl:gap-14'
          : 'md:flex-row md:justify-between md:gap-14'
      )}
    >
      <div
        className={cn(
          'flex flex-col justify-between gap-8 h-fit',
          cards.length > 3
            ? 'xl:gap-20 xl:max-w-[400px]'
            : 'md:gap-20 md:max-w-[400px]'
        )}
      >
        <div className="flex flex-col gap-2 md:gap-4">
          {tag && <Tag className="bg-foreground/10">{tag}</Tag>}

          {title && (
            <p className="text-title-medium md:text-title-large font-regular text-foreground">
              {textByLine(title, (part, i, length) => (
                <>
                  {part}
                  {i + 1 < length && <br />}
                </>
              ))}
            </p>
          )}
        </div>

        <div className="flex flex-col gap-5">
          {description && (
            <p className="text-eighteen md:text-twenty font-light text-foreground md:text-foreground/80">
              {textByLine(description, (part, i, length) => (
                <>
                  {part}
                  {i + 1 < length && <br />}
                </>
              ))}
            </p>
          )}

          {link?.[0] &&
            link?.[0]?.label &&
            link?.[0]?.link &&
            (() => {
              const { href, target, rel } = getAnchorFromCmsLink(link[0].link)
              return (
                <Button
                  as="a"
                  variant="outline"
                  rel={rel}
                  href={href}
                  target={target}
                  icon={getValidIcon(link[0].icon)}
                  alternativeText={link[0].alternative_text}
                >
                  <div className="flex items-center gap-2">
                    {link[0].label}
                    <Icon icon="arrow-right" className="opacity-60" />
                  </div>
                </Button>
              )
            })()}
        </div>
      </div>

      {cards.length && (
        <div
          className={`grid grid-cols-1 gap-5 ${
            cards.length > 3 ? 'lg:grid-cols-2' : 'md:grid-cols-1'
          }`}
        >
          {cards.map((card) => {
            return (
              <LinkCard
                key={card._uid}
                card={card}
                isTextOnly={cards[0].image?.filename === null}
              />
            )
          })}
        </div>
      )}
    </div>
  )
}

interface LinkCardProps {
  card: LinkCardStoryblok
  isTextOnly?: boolean
}

const LinkCard = ({ card, isTextOnly }: LinkCardProps) => {
  const { _uid, title, description, link, image } = card
  const { href, rel, target } = getAnchorFromCmsLink(link?.[0]?.link)

  return (
    <div
      className={cn(
        `flex gap-3.5 md:gap-6 rounded-4 bg-foreground/5 ${
          !isTextOnly
            ? 'lg:max-w-[630px] md:rounded-5 p-3'
            : 'lg:max-w-[414px] p-4'
        }`
      )}
    >
      {!isTextOnly && image && image.filename && (
        <div className="relative overflow-hidden rounded-[10px] md:rounded-3 w-[134px] aspect-square flex-shrink-0">
          <StoryblokImage
            fill
            priority
            width={undefined}
            height={undefined}
            asset={image}
            className="object-cover"
          />
        </div>
      )}
      {!isTextOnly && !image?.filename && (
        <div className="rounded-[10px] md:rounded-3 w-[134px] h-[134px] flex-shrink-0 bg-gray-200"></div>
      )}
      <div className="flex flex-col justify-between">
        <div className={`flex flex-col gap-0.5 ${isTextOnly ? 'mb-11.5' : ''}`}>
          <p className="text-eighteen md:text-twenty font-regular text-foreground">
            {title}
          </p>
          <p className="text-sixteen md:text-eighteen font-light text-foreground/80 line-clamp-3">
            {description}
          </p>
        </div>
        <Link
          href={href}
          rel={rel}
          target={target}
          arrow
          className="text-eighteen font-regular text-foreground"
        >
          {link?.[0]?.label}
        </Link>
      </div>
    </div>
  )
}
